import React from 'react'

// This ensures that the icon CSS is loaded immediately before attempting to render icons
import "@fortawesome/fontawesome-svg-core/styles.css";
import { config } from "@fortawesome/fontawesome-svg-core";

import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faVolumeMute } from '@fortawesome/free-solid-svg-icons'
import { faVolumeUp } from '@fortawesome/free-solid-svg-icons'

import { graphql, StaticQuery } from 'gatsby'
import BackgroundImage from 'gatsby-background-image'
import Headroom from 'react-headroom'

import Header from "../components/header"
import SEO from "../components/seo"
import styles from './home.module.scss'

import Vimeo from '@u-wave/react-vimeo'

// Prevent fontawesome from dynamically adding its css since we did it manually above
config.autoAddCss = false;

class IndexPage extends React.Component {
  constructor(props) {
      super(props);
      
      this.state = {
        value: false,
        volume: 0,
      };
          
      this.onChange = this.onChange.bind(this);
  }

  onChange(e){
      let value = this.state.value;
      value = e.target.checked;
      this.setState({value});

      if(this.state.value == true) {
          this.setState({ volume: 0 });
      } else {
          this.setState({ volume: 1 });
      }
  }

  render() {
    return (
      <div>
        <SEO title="Home" keywords={this.props.site.siteMetadata.keywords} image={this.props.placeholder_image.childImageSharp.fluid} />
        <div className={styles.header}>
            <Headroom>
                <Header />
            </Headroom>
          {this.props.children}
        </div>

        <div className={styles.row1_video_container}>
            <BackgroundImage 
                        fluid={this.props.placeholder_image.childImageSharp.fluid}
                        className={styles.row1_video_inner}>
      
            <Vimeo
                video={347246357}
                // video={242659975}
                title={"MindBuffer Interactive Art Installations"}
                width={640}
                height={360}
                autoplay={1}
                loop={1}
                autopause={0}
                muted={1}
                background={1}
                volume={this.state.volume}
            />

          </BackgroundImage>
        </div>

        <div className={styles.checkbox_wrap}> 
          <label className={styles.fancy_checkbox}>
              <input 
                type="checkbox"
                checked={this.state.value} 
                onChange={this.onChange}/>

              <FontAwesomeIcon icon={faVolumeMute} size="2x" className={styles.unchecked} />
              <FontAwesomeIcon icon={faVolumeUp} size="2x" className={styles.checked} />
          </label>
        </div>

      </div>
    )
  }
}

export default () => (
  <StaticQuery
      query={graphql`
        query {
          site {
              siteMetadata {
                  keywords
              }
          }
          placeholder_image: file(relativePath: { eq: "images/mindbuffer_laser/mindbuffer_promo_poster.jpg" }) {
            childImageSharp {
              fluid(maxWidth: 2940, maxHeight: 1654) {
                ...GatsbyImageSharpFluid
              }
            }
          }
        }
      `}
      render={(data) => (
          <IndexPage site={data.site} placeholder_image={data.placeholder_image} />
      )}
  />
)

